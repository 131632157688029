@import '../../../frontend/font-awesome/css/font-awesome';
@import '../../../frontend/css/bootstrap.min';
@import '../../../frontend/css/fonts';


$cofidis-red: #d0103a;
$cofidis-red2: #afafaf;
$cofidis-white: #fff;
$cofidis-darkgrey: #666;
$cofidis-gold: #dfcba5;

$color-main: $cofidis-red;
$color-secondary: $cofidis-white;
$color-tertiary: $cofidis-darkgrey;
$color-special: $cofidis-gold;
$color-tertiary-light: lighten($color-tertiary, 50%);

$font-size-text: 18px;
$header-height: 160px;
$footer-height: 260px;

$wrapper_width: 1140px;
$list_width: 870px;

/// Media queries mixins
@mixin mediaquery($value, $rule-width: 'min-width') {
    @media screen and (#{$rule-width}: $value) {
        @content;
    }
}
@mixin mobile {
    @include mediaquery('992px', 'max-width') {
        @content;
    }
}

body {
    font-family: 'Titillium Web';
    color: $color-tertiary;
}
.divider{
    float:left;
    width: 100%;
    height: 2px;
    background: #e6e6e6;
    margin-top:60px;
    margin-bottom:60px;
    &.v2{
           margin-top:10px;
            margin-bottom:60px;
    }
}

/*@import '../../../frontend/css/swiper';*/
@import 'common';
.only-des{
    display: block;
    @include mobile{
        display: none;
    }
}

.only-mob{
    display:none;
    @include mobile{
        display: block;
    }
}
.icon:before {
    width: 40px;
    height: 40px;
    background-color: $color-main;
    position: relative;
    border-radius: 50px;
    padding-top: 8px;
    top: -8px;
    color: #fff;
    font-size: 22px;
}
i.icon.icon-lock {
    position: relative;
    /* display: inline; */
    display: inline-block;
    width: 30px;
    height: 34px;
    line-height: 28px;
    vertical-align: text-top;
    margin-left: 5px;

    img.custom-icon-lock {
        position: absolute;
        top: 2px;
        left: 12px;
    }
}
.icon:before {
    width: 40px;
    height: 40px;
    background-color: #d0103a;
    position: relative;
    border-radius: 50px;
    padding-top: 8px;
    top: -8px;
    color: #fff;
    font-size: 22px;
    /* width: 1em; */
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    content: '';
}

#wrapper{
    max-width: $wrapper_width;
    margin: 0 auto;
    .page{
        padding-top: 200px;
        padding-bottom: 100px;
        .reset-password-message-error {
            max-width: 500px;
            width: 100% !important;
            margin-bottom: 30px;
            text-align: center;
            font-weight: 600;
            padding: 15px;
            width: 500px;
            margin: 0 auto;
            color: #a94442;
            background-color: #f2dede;
            border: solid 1px;
            border-color: #ebccd1;
            border-radius: 4px;
        }
        .reset-password-message-ok {
            max-width: 500px;
            width: 100% !important;
            margin-bottom: 30px;
            text-align: center;
            font-weight: 600;
            padding: 15px;
            width: 500px;
            margin: 0 auto;
            color: #3c763d;;
            background-color: #dff0d8;
            border: solid 1px;
            border-color: #d6e9c6;
            border-radius: 4px;
        }
        .call-us{
            display: none;

            @include mobile {
                display: inline;
            }
        }
        .alert-danger {
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 30px;
            font-weight: 600;
            h3 {
                font-size: 18px;
                text-align: center;
                margin-top: 0px;
            }
        }
        padding-left: 10px;
        padding-right: 10px;
        #concactBoxs{
            float:left;
            width:100%;
            padding-bottom:60px;
            h1{

                clear: both;
                width: 100%;
                .row{
                    clear: both;
                    float:none;
                    margin-left: 0;
                    margin-right:0;
                }
            }
        }
    }

    header {
        z-index: 1000;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        padding: 10px 30px;
        background-color: #fff;
        box-shadow: 0 0 20px -1px #00000020;

        @include mobile {
            height: auto;
            padding: 10px 25px 10px 20px
        }

        .top-header {
            height: 106px;

            .right-menu {
                float: right;
            }
        }

        .logo {
            float:left;
            width: 33.333333333%;

            img {
                max-width: 150px;
            }

            @include mobile {
                width: 50%;
            }
        }
        .back{
            float: left;
            width: 33.33333333%;
            text-align: center;
            padding-top: 40px;
            text-transform: uppercase;
            @include mobile {
                width:100%;
            }
        }
        .menu{
            float: right;
            width: 33.33333333%;
            @include mobile {
                width:50%;
            }
            &.menu-mobile{
                ul{
                    display: block;
                    width:100%;
                    float:left;
                    padding:0;
                    li{
                        display: block;
                        width:50%;
                        float:left;
                        img{
                            float:none;
                            margin: 0 auto;
                            display: block;
                            max-width: 100%;
                        }
                        a{
                            padding: 0 10px;
                            font-size:11px !important;
                        }
                        &.li-left{
                            text-align: left;
                        }
                        &.li-right{
                            text-align: right;
                            float:right;
                        }
                        &.li-center{
                            text-align: center;
                        }
                        i.icon.icon-lock{
                            width: 40px;
                        }
                    }
                }
            }
        }


        .right-menu {
            @include mobile{
                width: 50%;
                padding:0;
                margin:0;

            }
            > ul {
                height: 94px;
                line-height: 98px;
                @include mobile{
                    width: 100%;
                    padding:0;
                    margin:0;
                    display:table;
                    text-align: right;
                }
                margin-right: 10px;

                > li {
                    list-style: none;
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    .inl{
                        @mobile{
                            display:inline-block !important;
                        }
                    }

                    &:before {
                        display: none;
                    }

                    .icon:before {
                        width: 40px;
                        height: 40px;
                        background-color: $color-main;
                        position: relative;
                        border-radius: 50px;
                        padding-top: 8px;
                        top: -8px;
                        color: #fff;
                        font-size: 22px;
                    }
                    i.icon.icon-lock {
                        position: relative;
                        /* display: inline; */
                        display: inline-block;
                        width: 30px;
                        height: 34px;
                        line-height: 28px;
                        vertical-align: text-top;
                        margin-left: 5px;

                        img.custom-icon-lock {
                            position: absolute;
                            top: 2px;
                            left: 12px;
                        }
                    }
                    .icon:before {
                        width: 40px;
                        height: 40px;
                        background-color: #d0103a;
                        position: relative;
                        border-radius: 50px;
                        padding-top: 8px;
                        top: -8px;
                        color: #fff;
                        font-size: 22px;
                        /* width: 1em; */
                        font-family: "fontello";
                        font-style: normal;
                        font-weight: normal;
                        speak: none;
                        display: inline-block;
                        text-decoration: inherit;
                        /* width: 1em; */
                        content: '';
                        /* line-height: 1em; */
                    }

                    .kleo-search-nav {
                        @include mobile {
                            display: none;
                        }
                    }
                }
            }
        }

        .desktop-menu {
            width: 100%;
            height: 54px;
            line-height: 54px;
            background-color: $color-main;
            color: $color-secondary;
            text-align: center;


            @include mobile {
                height: auto;
                max-height: 4px;
                overflow: hidden;
            }

            a {
                color: $color-secondary;
            }

            ul li::before {
                display: none;
            }

            .primary-menu > ul {
                margin: 0;
                font-size: 0;

                > li {
                    font-size: $font-size-text;
                    text-transform: uppercase;
                    display: inline-block;
                    list-style: none;
                    margin: 0;
                    margin-right: -1px;

                    &:before,
                        &#nav-menu-item-search,
                        .caret {
                        display: none;
                    }

                    a {
                        border-left: 1px solid $color-secondary;
                        padding: 4px 18px;
                        transition: none;
                    }

                    &:first-child a {
                        border-left: none;
                    }

                    &:hover {
                        background-color: $color-secondary;

                        a {
                            color: $color-main;
                        }
                    }

                    &.menu-item-has-children {
                        &:hover {
                            .sub-menu {
                                display: block;
                                margin: 0;
                                border: 0;
                                text-align: left;
                                min-width: 100%;
                                li {
                                    font-size: $font-size-text;
                                    text-transform: none;
                                    display: inline-block;
                                    list-style: none;
                                    border-bottom: 0;
                                    margin: 0;
                                    padding: 10px 18px;
                                    width: 100%;
                                    line-height: 1.11111rem;

                                    &:first-child {
                                        margin-top: 20px;
                                    }
                                    &:last-child {
                                        margin-bottom: 20px;
                                    }

                                    a {
                                        color: $color-tertiary;
                                        background-color: $color-secondary;
                                        transition: all .2s ease;
                                        padding: 0;
                                        display: inline-block;

                                        &:after {
                                            content: '';
                                            display: block;
                                            width: 100%;
                                            height: 1px;
                                            border-bottom: 1px solid $color-secondary;
                                            transition: all .2s ease;
                                        }
                                    }

                                    &:hover {
                                        a {
                                            color: $color-main;
                                            background-color: $color-secondary;

                                            &:after {
                                                border-bottom: 1px solid $color-main;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        color: $color-secondary;
        .footer-inner-container {
            background-color: $color-main;
        }
        .row {
            max-width: 1000px;
            margin: auto;

            .footer-column {
                border-right: 1px solid $color-secondary;
                @include mobile{
                    border-right: none;
                }
                margin: 40px 0;
                padding: 5px 40px;
                min-height: 230px;

                @include mobile {
                    min-height: 0;
                    margin: 0;
                }

                &:last-child {
                    border-right: 0;
                }
                li{
                    margin:0 0 5px;
                    a{
                        font-size:14px;
                    }
                }
                a {
                    color: $color-secondary;
                    font-weight: 600;
                }
                p {
                    margin: 0 0 10px;
                    font-size: 12px;
                }

                .footer-logo {
                    margin: 0 0 46px;
                }

                .contact-container {
                    h4 {
                        margin-bottom: 30px;
                    }
                    p{
                        font-weight: 600;
                    }
                }
                .footer-nav {
                    .follow-us{
                        h4,p{
                            float:left;
                        }
                        p{
                            padding: 5px;
                        }
                    }
                    ul{
                        float:left;
                        clear: both;
                        padding: 0;
                    }
                    @include mobile {
                        padding-left: 0px;
                    }
                    li {
                        text-transform: uppercase;
                        list-style: none;

                        &:before {
                            content: none;
                        }

                        &.social-title {
                            margin: 0;
                        }

                        h4 {
                            margin-bottom: 0;
                        }

                        &.footer-social-icon {
                            display: inline-block;
                            font-size: 20px;
                            margin: 30px;
                            margin-left: 0;
                        }
                    }
                }
            }
            .footer-legal-info{
                width: 110% !important;
                p{
                    font-weight: 600;
                }
            }
        }
    }
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button, .cofidis-btn {
    padding: 10px 40px;
    border: 0;
    border-radius: 20px;
    font-size: rem(13);
    color: $color-secondary!important;
    background: $color-main;
    user-select: none;
    cursor: pointer!important;
    &:focus, &:active{
        outline: none;
    }
}
input::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

[role="button"] {
    display: inline-block;
    white-space: pre;
    text-align: center;
    text-decoration: none;
    color: inherit;
    cursor: default;
    user-select: none;
}

a, a:hover, a:active, a:focus, a:visited{
    color: $cofidis-red;
}

.page-dashboard,
.page-downloads{
    display: none;
}
.login-page-title {
    font-weight: 600;
    color: #d0103a;
    margin-top: 40px;
}

.login-form, .reset-password-form{
    max-width: 500px;
    margin: 30px auto 0;
    padding: 20px 20px 0;
    > div{
        margin-bottom: 30px;
    }
    label {
        display: block;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    input[type=email], input[type=password]{
        display: block;
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px;
        background-color: #fff;
        border: 1px solid #252525;
        box-shadow: none;

        &:focus{
            outline: none;
        }
    }
    button{
        margin: 0 0 30px;
    }
}

.categories-list{
    list-style: none;
    padding: 0 0 65px 0 !important;
    border-bottom: 2px solid $color-tertiary-light;
    margin: 0 0 65px 0;
    float:left;
    width:100%;
    > li > span{
        text-transform: uppercase;
        line-height: 40px;
        color: $cofidis-red;
        font-weight: bold;
    }
    > li > ul{
        list-style: none;
        padding: 20px 0 0;
        margin-left:15px;
        margin-right:15px;
        li.show-more{
            padding: 15px;
            a{cursor: pointer;}
        }
        li:not(.show-more){
            display: table;
            width: 100%;
            padding:5px;
            &:nth-child(odd){
                background-color: $color-tertiary-light;
            }
            & > span{
                text-transform: uppercase;
                vertical-align: middle;
                display: table-cell;
            }
            & > a{
                display: table-cell;
                width: 25px;
                img{
                    width: 100%;
                    max-width: 100%;
                }
            }
            &:before{
                display: table-cell;
                content: '-';
                vertical-align: middle;
                width: 10px;
            }
        }
    }
}
.categories-list.emp{
    li  ul li:before{
                display:none !important;
            }
}


.submit-password-recover {
    position: relative;

    .loader {
        position: absolute;
        bottom: initial;
        top: 60px;
        text-align: center;
        left: 0;
        right: 0;
    }
}
/*Style Documents*/
.tabs{
    float: none;
    margin: 0 auto;
    padding: 0 15px;
    overflow: hidden;
    text-align: center;
    .categories-tab {
        display: inline-block;
    }
    .col-xs-4,.col-xs-12{
        padding-left: 1px;
        padding-right: 1px;
    }

    .tab_button{
        background: $cofidis-red2;
        color: white;
        padding: 10px;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        width: 100%;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        :focus {
            outline: none !important;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            outline: none !important;

        }
        @include mobile{
            font-size:10px;
        }
        border:none;
    }
    .active{
        background: $cofidis-red;
        border:none;
        outline: none !important;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            outline: none !important;
    }
    .active:focus {
        outline: none !important;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            outline: none !important;
    }

    .tab_button:hover{
        background: #676666;
    }
    .tab_button.active:hover{
        background: $cofidis-red;
        border:none;
    }
}

.sections-list{
    .titleHero{
        font-family: "Titillium Web";
        font-size: 26px;
        font-weight: 600;
        color: #d0103a;
        text-transform: uppercase;
        text-align: center;
        margin-bottom:20px;
    }
}
.categories-list{
    display: none;
}

.sections-list  >.categories-list:first-of-type {
    display: block;
    padding: 0;
}

.desktop-menu{
    display:none;
}


.insideBox{
    padding: 10px 0;
    float:left;

    .title{
        margin-bottom: 20px;
        font-family: "Titillium Web";
        font-size: 18px;
        font-weight: 600;
        color: #d0103a;
        text-transform: uppercase;
        text-align:center;
    }
    .contentModule{
        font-family: "Titillium Web";
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        color: #252525;
        text-align:left;
    }
    .riqFoto{
        min-height: 161px;
        padding:0;
        margin:0;

        img{
             margin-left:10px;
            @include mobile {
                margin-left:auto;
                transform: initial;
                float:none;
                margin: 0 auto;
            }
        }
    }

    .titleHero {
        font-family: "Titillium Web";
        font-size: 18px;
        font-weight: 600;
        color: #d0103a;
        text-transform: uppercase;
        text-align:center;
    }
    .subtitleHero{
        font-family: "Titillium Web";
        font-size: 18px;
        line-height: 1.2;
        font-weight: 400;
        color: #666;
        text-align:center;

    }

}
#modulesBox{
    .insideBox{
        background: #f2f2f2;
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px;
        .contentModule{
            font-size:14px;
            @include mobile{
                padding: 10px 0;
            }
        }
        .titlediv{
            h1{
                margin: 20px 0 0;
               @include mobile{
                    margin: 20px 0;
                }
            }
        }
        .c_cta{
            padding: 20px 0 0;
              @include mobile{
                    margin: 20px 0 30px;
                    padding: 0;
                }
        }
    }
    clear: both;
}

#concactBox{
    .title,.subtitle{
        font-family: "Titillium Web";
        font-size: 18px;
        font-weight: 600;
        color: #252525;
        text-transform: uppercase;
        text-align: center;
        display:block;
    }

    .mobile{
        width:200px;
        float:none;
        margin:0 auto;
        overflow:hidden;
        .cbox{
            padding: 10px 10px;
            float:left;
            margin:0;
            i{
                background: #efeeee;
            }
        }
    }
    .title{

        font-size:14px;
        padding: 5px;
    }

    .titleHero {
        font-family: "Titillium Web";
        font-size: 36px;
        font-weight: 800;
        color: #d0103a;
        text-align:center;
        padding-bottom: 20px;
        margin: 0;
    }
    .cbox{
        .linkmob{
            text-decoration: none;
        }
        i{
            font-size: 50px;
            color: #252525;
            margin: 0 auto;
            display: block;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            border-radius: 100%;
            display: block;
        }

    }
    .cboxwrapper{
        border: 0px solid #252525;
        min-height: 240px;
        height: 100%;
        padding: 50px 0;
        background: #efeeee;
        border-radius: 20px;
        @include mobile{
            background: initial;
        }
        a {
            color: #252525;
        }
    }
}
#portalesBox,#promotionsBox{
    max-width: 850px;
    margin: 0 auto;
    .riquadro{
        min-height: 435px;
        @include mobile{
            min-height:auto;
            margin-bottom: 50px;
        }
        .subtitleHero{
            min-height: 60px;
            @include mobile{
                min-height:auto;
            }
            font-size:14px;
        }
    }
    .img_w {
        box-shadow: 0 0 10px #00000045;
        border-radius: 6px;
        overflow: hidden;
    }
    .c_cta {
        clear: both;
        display: block;
        padding: 1px;
        padding-top: 0px;
    }
    .wpzero-cta {
        display: block;
        clear: both;
        margin-top: 2px;
        max-width: 280px;
        margin: 0 auto;
        padding: 11px;
        background-color: #d0103a;
        border-radius: 50px;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .wpzero-cta a {
        display: inline-block;
        width: 100%;
        color: #ffffff;
    }

    .wpzero-cta a:hover {
        color: white !important;
        text-decoration: none;
    }

    .titleHero {
        margin-bottom: 20px;
        font-family: "Titillium Web";
        font-size: 18px;
        font-weight: 600;
        color: #d0103a;
        text-transform: uppercase;
        text-align:center;
    }
    .headertitle{
        font-size:26px;
        margin-bottom: 20px;
    }
    .subtitleHero{
        font-family: "Titillium Web";
        font-size: 18px;
        line-height: 1.2;
        color: #666;
        max-width: 100%;
        text-align: center;
        margin: 12px auto;
    }
}

#modulesBox{
    max-width: 850px;
    margin: 0 auto;
    .c_cta {
        clear: both;
        display: block;
        padding: 1px;
        padding-top: 10px;
    }
    .wpzero-cta {
        display: block;
        clear: both;
        margin-top: 2px;
        max-width: 280px;
        margin: 0 auto;
        padding: 11px;
        background-color: #d0103a;
        border-radius: 50px;
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    .wpzero-cta a {
        padding: 16px;
        color: #ffffff;
    }

    .wpzero-cta a:hover{
        color: white !important;
        text-decoration: none;
    }
    .titleHero {
        font-family: "Titillium Web";
        font-size: 26px;
        font-weight: 600;
        color: #d0103a;
        text-transform: uppercase;
        text-align:center;
        display: none;
    }

    .subtitleHero{
        padding-top: 10px;
        font-family: "Titillium Web";
        font-size: 18px;
        line-height: 1.2;
        font-weight: 600;
        color: #666;
        max-width: 100%;
        text-align: center;
        text-align: center;
        margin: 12px auto;
        display: none;
    }
}


/*Hero Agents*/
#heroagents {
    margin-bottom: 50px;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 20px;

    .titleHero {
        color: #d0103a;
        font-weight: 800;
        margin-bottom: 20px;
        text-align: center;
    }

    .subtitleHero {
        color: #d0103a;
        font-size: 26px !important;
        font-weight: 600;
        text-align: center;
        padding:0;
        margin:0;
    }
    #dimage , .newsContent {
        font-size:18px;
        padding-bottom:20px;
        text-align: center;
    }
    #dimage {
        display: block;
        margin: 30px auto;
    }

}


@media only screen and (min-width: 1001px) {
    #heroagents #mimage {
        display: none;
    }
    #heroagents #dimage , #heroagents .newsContent {
        max-width: 960px;
        margin:10px auto 10px auto;
        font-size:18px;
    }
}
@media only screen and (max-width: 414px) {

    #heroagents .newsContent{
        max-width: 300px;
        margin: 0 auto;
    }
    #heroagents #dimage {
        display: none;
    }
    #heroagents #mimage {
        margin: 0 auto;
        margin-bottom: 30px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1000px) {

    #heroagents #mimage {
        display: none;
    }
    #heroagents #dimage {
        max-width: 708px;

    }
    #heroagents .newsContent {
        max-width: 708px;
        margin: 0 auto;
        word-wrap: break-word;
    }
}
@media only screen and (max-width: 767px) {
    #heroagents #dimage {
        display: none !important;
    }
}

/* Banner Webinar */
#bannerWebinar {
    margin-bottom: 50px;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 50px;

    img {
        border-radius: 10px;
        transition: transform 0.3s;
        box-shadow: 1px 0 10px -5px #00000030;
    }
}

/**
 * Swiper 3.4.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: December 13, 2016
 */
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    /* Fix of Webkit flickering */
    z-index: 1;
    margin-top: 60px;
}
.swiper-container-no-flexbox .swiper-slide {
    float: left;
}
.swiper-container-vertical > .swiper-wrapper {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate(0px, 0px);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto;
}
.swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex: 0 0 auto;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-transition-property: -webkit-transform, height;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    -ms-transition-property: -ms-transform;
    transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}
.swiper-wp8-vertical {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}
/* Arrows */
.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    -moz-background-size: 27px 44px;
    -webkit-background-size: 27px 44px;
    background-size: 27px 44px;
    background-position: center;
    background-repeat: no-repeat;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d0103a'%2F%3E%3C%2Fsvg%3E");
    left: 10px;
    right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d0103a'%2F%3E%3C%2Fsvg%3E");
    right: 10px;
    left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
/* Pagination Styles */
.swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
    opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 0;
    width: 100%;
}
/* Bullets */
.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
}
button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
    background: #fff;
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: #d0103a;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
    background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
    background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    -webkit-transform: translate3d(0px, -50%, 0);
    -moz-transform: translate3d(0px, -50%, 0);
    -o-transform: translate(0px, -50%);
    -ms-transform: translate3d(0px, -50%, 0);
    transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 5px 0;
    display: block;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
}
/* Progress */
.swiper-pagination-progress {
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
    background: #007aff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    -ms-transform-origin: right top;
    -o-transform-origin: right top;
    transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
    background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
    background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
    background: #000;
}
/* 3D Container */
.swiper-container-3d {
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    -o-perspective: 1200px;
    perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-right {
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-top {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
    /* Safari 4+, Chrome */
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Chrome 10+, Safari 5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 3.6-15 */
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    /* Firefox 16+, IE10, Opera 12.50+ */
}
/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
    /* Windows 8 IE 10 fix */
    -ms-perspective: 1200px;
}
/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
    overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}
/* Cube */
.swiper-container-cube .swiper-slide {
    visibility: hidden;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 100%;
    height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
    -webkit-transform-origin: 100% 0;
    -moz-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0;
}
/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
    pointer-events: none;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}
.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
}
.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
/* Scrollbar */
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%;
}
.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    left: 0;
    top: 0;
}
.swiper-scrollbar-cursor-drag {
    cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    -webkit-transform-origin: 50%;
    -moz-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
    animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    -webkit-background-size: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes swiper-preloader-spin {
    100% {
        transform: rotate(360deg);
    }
}

.slider-iframe{
    margin: 0 auto;
    display: block;
    margin-bottom: 60px;
}
@media only screen and (max-width: 414px){
    .slider-iframe {
        max-width: 75% !important;
        height: 162px !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
        top: 85px !important;
    }
}

.button-submit{
    button{
      padding: 10px 40px !important;
      border: 0 !important;
      border-radius: 20px !important;
      color:#fff !important;
      background:#d0103a !important;
      -webkit-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      cursor: pointer !important;
      margin: 0 0 30px !important;
      font-weight: 600;
      font-size: 18px;
    }
    .m-t{
      color:#d0103a;
    }
  }