

// #wrapper > div:first-child, footer{
//   display: none;
// }


.div-form-login{



  .md-button{


    @media only screen and (max-width: 767px) {
      & {
       font-size:12px;
      }
    }

  }

  .md-content{
overflow: hidden !important;
  }

  .md-padding{
        padding: 0px;
        overflow: hidden ;
        padding-bottom: 10px;
  }

  .container-form{
    border: 1px solid $cofidis-red;
    padding: 0px;
  }

  .main-title{
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    padding: 18px;
    padding-left: 0px;

    line-height: 36px;
    border-bottom: 1px solid $cofidis-red;
  }

  h2{
      font-size: 18px;
      text-align: center;
  }
  p{
           padding-right: 50px;
          text-align: center;
          padding-left: 50px;
          margin-bottom: 20px;
  }

  .login-form{
    padding: 0px 15%;
  }
  .login-form button{
    width: 100%;
  }

}

html{
  background-color: $cofidis-white;
}

.md-nav-item:first-of-type {
  margin-left: -4px;
}

li.md-nav-item{
	width:50%;
}
._md-nav-button{
	width: 100%;

}
.md-button.md-accent{

  color:$cofidis-red !important;

}

.md-nav-bar md-nav-ink-bar{
    color:$cofidis-red !important;
    background-color:$cofidis-red !important;

}
